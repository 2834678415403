import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },

  {
    path: "/voucherList",
    name: "VoucherList",
    component: () =>
      import(
        /* webpackChunkName: "voucherList" */ "../views/VoucherList/index.vue"
      ),
  },

  {
    path: "/gasStation",
    name: "GasStation",
    component: () =>
      import(/** webpackChunkName: "" **/ "../views/GasStation/index.vue"),
  },

  {
    path: "/cashier",
    name: "Cashier",
    component: () =>
      import(/* webpackChunkName: "cashier" */ "../views/Cashier/index.vue"),
  },

  {
    path: "/cashier/:resultType",
    name: "CashierResult",
    component: () =>
      import(
        /* webpackChunkName: "cashierResult" */ "../views/Cashier/result.vue"
      ),
  },

  {
    path: "/activities",
    name: "Activities",
    component: () =>
      import(
        /* webpackChunkName: "Activities" */ "../views/Activities/index.vue"
      ),
    children: [
      {
        path: "guangda",
        name: "ActivitiesGuangda",
        component: () =>
          import(
            /* webpackChunkName: "ActivitiesGuangda" */ "../views/Activities/guangda/index.vue"
          ),
        meta: {
          title: "光大银行",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
